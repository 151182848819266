import {
  ExpiredStatuses,
  RegistrationQuery,
} from '@/components/consultation/consultation.typedefs';
import { getConsultationRedirectLink } from '@/controllers/router/router.utils/learn';
import { PromoCodeLandingFragment } from '@/controllers/promoCode/generated/promoCodeLanding.fragment.generated';
import { DAY } from '@/constants';

export const getNextExpirationTime = (
  startedAtTime: number,
  repeatDaysCount: number,
) => {
  const repeatIntervalMs = repeatDaysCount * DAY;
  const currentTime = Date.now();

  if (startedAtTime >= currentTime) {
    return startedAtTime + repeatIntervalMs;
  }

  const intervalsPassed = Math.ceil(
    (currentTime - startedAtTime) / repeatIntervalMs,
  );

  return startedAtTime + (intervalsPassed * repeatIntervalMs);
};

export const successPageData = ({
  courseSlug,
  shouldRedirectToTrialIntro,
  shouldRedirectToIntroductionTopic,
  moduleSlug,
  topicSlug,
}: {
  courseSlug?: string;
  shouldRedirectToTrialIntro?: boolean;
  shouldRedirectToIntroductionTopic?: boolean;
  moduleSlug?: string;
  topicSlug?: string;
}) => ({
  redirectLink: getConsultationRedirectLink({
    courseSlug,
    shouldRedirectToTrialIntro,
    shouldRedirectToIntroductionTopic,
    moduleSlug,
    topicSlug,
  }),
  text: 'success_page_pt_text',
  buttonName: 'success_page_pt_submit',
});

export const buildQuery = (query: RegistrationQuery) => {
  const queryString = Object.entries(query)
    .map(([key, value]) => (
      `${key}=${value}`
    ))
    .join('&');

  return queryString.length > 0
    ? `?${queryString}`
    : '';
};

interface Options {
  promoCode: PromoCodeLandingFragment;
  promoFirstInteractionTime: number;
}

export const getTimerExpiredAtTime = ({
  promoCode,
  promoFirstInteractionTime,
}: Options): number => {
  const {
    status,
    startedAt,
    expiredAt,
    timerConfig,
  } = promoCode;

  if (ExpiredStatuses.includes(status)
    || (expiredAt && Number(expiredAt) <= Date.now())
  ) {
    return 0;
  }

  if (expiredAt) {
    return Number(expiredAt);
  }

  const startedAtTime = timerConfig?.useFirstInteractionTime
    ? promoFirstInteractionTime
    : Number(startedAt);

  const repeatDaysStep = timerConfig?.repeatDaysStep || 3;

  return getNextExpirationTime(startedAtTime, repeatDaysStep);
};
